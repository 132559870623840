<template>
  <div class="info">
    <img :src="imageUrl(imageIndex)" class="image" v-if="info.images?.length > 0">

    <div class="image-slide-buttons-container" v-if="info.images.length > 1">
      <div class="image-button" @click="prevImage"><img src="../assets/images/arrow-left.svg"></div>
      <div class="image-button" @click="nextImage"><img src="../assets/images/arrow-right.svg"></div>
    </div>

    <h1 class="creator">{{ info ? info.name : "" }}</h1>
    <p class="description" v-html="info.description[$i18n.locale]"></p>
    
    <div class="insta" v-if="info.insta && info.insta.trim() != ''" @click="info ? openInsta(info.insta) : undefined">
      <img src="@/assets/images/instagram.png">
      <span>@{{info.insta}}</span>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'Info',
  data() {
    return {
      info: undefined,
      imageIndex: 0
    }
  },
  computed: {
    ...mapGetters(["getInfo"])
  },
  methods: {
    openInsta(username) {
      var win = window.open(`https://www.instagram.com/${username}`, '_blank');
      win.focus();
    },
    imageUrl(index) {
      if (this.info) {
        return require(`@/assets/images/werken/${this.info.images[this.imageIndex]}`);
      }
      return "";
    },
    prevImage() {
      if (this.imageIndex == 0) {
        this.imageIndex = this.info.images.length - 1;
      } else {
        this.imageIndex -= 1;
      }
    },
    nextImage() {
      if (this.imageIndex == this.info.images.length - 1) {
        this.imageIndex = 0;
      } else {
        this.imageIndex += 1;
      }
    }
  },
  created() {
    var name = this.$route.query.name;
    this.info = this.getInfo(name);
    this.imageIndex = this.$route.query.imageIndex ? parseInt(this.$route.query.imageIndex) : 0;
  }
}
</script>

<style lang="scss" scoped>
  @import "@/assets/sass/libs/_mixins.scss";
  @import "@/assets/sass/libs/_vars.scss";
  
  .info {
    display: flex;
    flex-direction: column;
    font-family: "DIN-Condensed-Bold", sans-serif;
    font-weight: bolder;
  }

  .image {
    width: 100%;
    max-height: 400px;
    object-fit: contain;
  }

  .image-slide-buttons-container {
    display: flex;
    justify-content: center;
  }

  .image-button {
    background-color: $blue;
    color: $red;
    width: 44px;
    height: 44px;
    border-radius: 22px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 10px;
    cursor: pointer;
  }

  .creator {
    padding: 2% 5%;
    color: $red;
    text-transform: uppercase;
    margin: auto;
    width: 100%;
    max-width: 800px;
    text-align: center;
  }

  .description {
    padding: 0 5%;
    color: $blue;
    padding-bottom: 40px;
    width: 100%;
    max-width: 800px;
    margin: auto;
  }

  .insta {
    margin: auto;
    margin-bottom: 40px;
    height: 44px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    img {
      width: 44px;
      height: 44px;
      padding: 10px;
    }
    
    span {
      padding-top: 5px;
      font-size: 20px;
      font-weight: bolder;
      text-transform: uppercase;
      color: $blue;
    }
  }
</style>